<template>
  <div class="login-common-wrapper">
    <div class="dialog-header update-login-dialog-header">
      <div v-if="isSend && step === 1" class="back-icon" @click="backHandler">
        <i class="iconfont icon-more-left" />
      </div>
      <div v-else-if="step > 1 && step !== 4" class="back-icon" @click="backIndex(1)">
        <i class="iconfont icon-more-left" />
      </div>
      <div class="logo" :class="`logo-${$store.state.locale}`">
        <img v-lazy-load :data-src="logoUrl">
      </div>
      <div class="operate-dialog-icon close-icon" @click="closePageHandler">
        <img v-lazy-load data-src="~assets/touch/images/login/close-icon.png">
      </div>
    </div>
    <div class="update-login-dialog-body">
      <!-- <h3 v-if="!isSend" class="login-headline font-semibold new-login-font-bold">
        <template v-if="firstLogin">
          {{ $t('login.login.firstLogin') }}3
        </template>
        <template v-else>
          {{ $t('login.login.loginArgain') }}2
        </template>
      </h3> -->
      <LoginForm
        ref="loginForm"
        :login-trigger-source="loginTriggerSource"
        :is-group="isGroup"
        :success-url="successUrl"
        @login-success="loginSuccessHandler"
        @changeTitle="textShowHandler"
        @changeCodeSend="changeCodeSend"
        @starsChange="starsChange"
        @stepChange="stepChange"
      />
    </div>
  </div>
</template>
<script>
import bus from 'common/bus.js';
import { oneApi } from 'config/api';
import LoginForm from 'components/login/newLoginForm';
import { elementOfArray } from 'common/utils.js';
import { Storage } from 'common/utils';
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';

export default {
  components: { LoginForm },
  data() {
    return {
      unpaidList: {},
      type: 'phone',
      isSend: false,
      isAccountSend: false,
      valueCode: '',
      firstLogin: true,
      isStarsChange: 0,
      step: 1,
    }
  },
  computed: {
    hasUnpaidList() {
      return this.$store.state.ucenter.unpaidList.toBeContinueSku || this.$store.state.ucenter.unpaidList.inCartSku
    },
    phoneCodeList() {
      return this.$store.state.phoneCodeList;
    },
    cancelUrl() {
      return this.$route.query.cancelUrl || '';
    },
    successUrl() {
      return this.$route.query.successUrl || '';
    },
    triggerSrc() {
      return this.$route.query.triggerSrc || '';
    },
    loginTriggerSource() {
      return this.$route.query.triggerSource || '';
    },
    isGroup() {
      console.log(this.$route.query.isGroup)
      return this.$route.query.isGroup && this.$route.query.isGroup !== 'false'
    },
    logoUrl() {
      return (
        this.$store.state.website.config.logo || this.$t('common.logo.normal')
      );
    },
  },
  mounted() {
    // const { verifyKey } = process.env;
    // gRecaptcha.createScript.call(this, verifyKey)
    const lastModified = localStorage.getItem('lastModified');
    if (lastModified) {
      this.firstLogin = false;
    }
    this.init();
    bus.$on('touch.courseRegister.quickLogin.updateStep', this.updateCurrentStep);
  },
  methods: {
    textShowHandler(obj) {
      this.type = obj.type;
      this.isSend = obj.isSend;
      if (this.isSend) {
        this.valueCode = obj.valueCode
      }
    },
    checkIt() {
      if (this.hasUnpaidList) {
        bus.$emit('openUnpaidDialog');
        localStorage.setItem('first_unpaid_remind', true);
      }
    },
    async  getUnpaidList() {
      const res = await this.$axios.post(oneApi.unpaidRemind, {}, { rewritePostBody: true })

      if (Number(res.code) !== 0) {
        this.$Toast(res.msg);
        return;
      }

      const resData = res.data || {};
      this.unpaidList = resData
      this.$store.commit('ucenter/SET_UNPAID_LIST', resData)
      console.log(this.$store.state.ucenter.unpaidList)
    },
    init() {
      this.initCountryCallingCode();
    },
    initCountryCallingCode() {
      // 获取当前国家代码
      const { locale } = this.$store.state;
      // 初始化手机区号对象
      this.$store.commit('ucenter/SET_SELECTED_PHONE_AREA_CODE', elementOfArray('countryCode', locale, this.phoneCodeList));
    },
    updateCurrentStep(nextStepName) {
      this.currentStep = nextStepName;
    },
    closePageHandler() {
      if (this.cancelUrl) {
        this.$router.replace(this.cancelUrl);
      } else {
        // 默认返回上一页
        this.$router.back();
      }
    },
    backHandler() {
      this.$refs.loginForm.backFunc();
    },
    async loginSuccessHandler() {
      if (this.successUrl) {
        this.$router.replace(this.successUrl);
      } else {
        this.$router.replace('/')
      }

      const { triggerSrc } = this

      // 续报弹窗
      await this.getUnpaidList()
      const storage = new Storage();
      if ((!storage.getItem('first_unpaid_remind')) && this.hasUnpaidList) {
        bus.$emit('openUnpaidDialog');
        storage.setItem({
          name: 'first_unpaid_remind',
          value: true,
          expires: 259200000, // 3 days
        })
        return
      }

      // 成功后跳转路径
      if (triggerSrc === 'BUY_BOOK') {
        bus.$emit('goods.detail.buy')
      }
      if (triggerSrc === 'REGISTRATION_COURSE') {
        bus.$emit('courses.detail.registrationCourses', true)
      }
      if (triggerSrc === 'FOLLOW_COURSE') {
        bus.$emit('courses.detail.registrationCourses', false)
      }
    },
    backIndex(num) {
      console.log(num, 'backIndex');
      if (this.isSend && this.step === 1) {
        this.$refs.loginForm.backFunc();
        return
      }
      if (this.isAccountSend && this.step === 2) {
        this.$refs.loginForm.backAccountSend();
        return
      }
      this.$refs.loginForm.backIndex(num);
    },
    changeCodeSend(isAccountSend) {
      this.isAccountSend = isAccountSend;
    },
    stepChange(num) {
      console.log(num, 'stepChange');
      this.step = num;
    },
    starsChange(num) {
      this.isStarsChange = num
      let typeText = ''
      if (num === 1 || num === 2) {
        typeText = '注册'
      }
      if (num === 3) {
        typeText = '登录'
      }
      // 注册登录弹框曝光埋点
      this.Sensors.track('login_show', {
        type: typeText,
      })
    },
  },

}
</script>
<style lang="scss" scoped>
.login-common-wrapper {
  padding: 20px;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;

  .new-login-font-bold {
    font-family: 'Montserrat-Bold', Montserrat, SFProRounded, 'SF Pro Rounded', 'SFPro-Semibold', Helvetica, Roboto, Arial, serif;
  }

  .update-login-dialog-header {
    height: 64px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .operate-dialog-icon {
      height: 24px;
      cursor: pointer;

      img {
        height: 100%;
      }
    }

    .back-icon {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      font-size: 24px;
      color: var(--txt-color-lv2);
    }

    .logo {
      height: 24px;

      img {
        height: 100%;
      }
    }
  }

  .update-login-dialog-body {
    // padding-top: 20px;

    .login-headline {
      // height: 24px;
      font-size: 20px;
      font-weight: bold;
      color: var(--txt-color-lv1);
      line-height: 24px;
    }
  }
}
</style>
<style>
.grecaptcha-badge { visibility: hidden; }
</style>
