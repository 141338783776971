<template>
  <div
    class="login-account-box phone-email-input-container"
    :data-placeholder="$t('login.loginInfo.MobileOrEmail')"
    :class="currentFocus"
  >
    <van-field
      v-model="inputValue"
      class="
        phone-input
        font-medium
        login-input
        account-input
        phone-box
        new-login-font
      "
      :class="[{ 'is-email-box': isEmail }, currentStatus]"
      name="phoneOrEmail"
      :maxlength="isEmail ? 45 : 16"
      autocomplete="off"
      :clearable="true"
      :disabled="disabled"
      :rules="rules"
      @blur="inputBlurHandler"
      @focus="inputFocusHandler"
    >
      <template slot="left-icon">
        <div v-if="disabled" class="phone-country-code-container">
          +{{ areaCode }}
        </div>
        <div v-else class="phone-country-code-container" @click="openCountryCodePopup">
          +{{ phoneAreaCode.value }}
          <i class="iconfont icon-arrow-bottom" />
        </div>
      </template>
    </van-field>
    <div v-show="showSelect" class="select-container">
      <ul class="code-list">
        <li
          v-for="code in phoneCodeList"
          :key="code.countryCode"
          class="code-item self-slect"
          :class="{ active: phoneAreaCode.value === code.value }"
          @click="countryCallingCodeChangeHandler(code, $event)"
          @focus="selectFocusHandler"
          @blur="selectBlurHandler"
        >
          <div class="area">
            {{ code.countryName }}
          </div>
          <div class="code">
            +{{ code.value }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { filterBlank } from 'common/utils.js';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    lastModified: {
      type: Object,
      default: () => ({}),
    },
    areaCode: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    const { locale } = this.$store.state;
    return {
      showSelect: false,
      locale,
      inputValue: '',
      phoneAreaCode: {},
      phoneCodeList: this.$store.state.phoneCodeList,
      currentStatus: '',
      currentFocus: '',
      isEmail: true, // 默认是邮箱
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    inputValue: {
      immediate: true,
      handler(val) {
        this.$emit('input', val)
        this.isEmail = !/^\d+$/.test(val);
        this.$emit('update-type', this.isEmail ? 'email' : 'phone');
      },
    },
    phoneAreaCode: {
      handler(val) {
        this.$emit('areaChange', val);
      },
      immediate: true,
      deep: true,
    },
    lastModified() {
      if (
        Object.keys(this.lastModified).length
        && this.lastModified.type === 'phone'
      ) {
        this.inputValue = this.lastModified.accountName;
        const data = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
        this.phoneAreaCode = data;
      } else if (Object.keys(this.lastModified).length && this.lastModified.type === 'email') {
        this.inputValue = this.lastModified.accountName
      }
      if (this.inputValue) {
        this.currentFocus = 'input-focus';
      }
    },
  },
  mounted() {
    document.querySelector('body').addEventListener('click', this.handleSelect);
    this.init();
  },
  beforeDestroy() {
    document
      .querySelector('body')
      .removeEventListener('click', this.handleSelect);
  },
  methods: {
    async init() {
      if (!this.phoneCodeList.length) {
        await this.$store.dispatch('requeryBasicData', this.$axios);
        this.phoneCodeList = [...this.$store.state.phoneCodeList];
      }
      // 初始化手机区号对象
      let initPhoneAreaCode = '';
      if (Object.keys(this.lastModified).length > 0) {
        this.inputValue = this.lastModified.accountName;
        // eslint-disable-next-line prefer-destructuring
        initPhoneAreaCode = this.phoneCodeList.filter(
          (item) => item.value === this.lastModified.countryCallingCode,
        )[0];
      } else {
        // 初始化手机区号对象
        initPhoneAreaCode = this.$store.state.defaultPhoneCode || this.phoneCodeList[0];
      }
      this.phoneAreaCode = initPhoneAreaCode;

      if (this.inputValue) {
        this.currentFocus = 'input-focus';
      }
    },
    // 打开手机区号选择器
    openCountryCodePopup(e) {
      e.stopPropagation();
      this.showSelect = !this.showSelect;
      this.currentStatus = this.currentStatus ? '' : 'is-active';
    },
    // 修改手机区号逻辑处理
    countryCallingCodeChangeHandler(args, e) {
      e.stopPropagation();
      this.phoneAreaCode = args;
      this.showSelect = false;
      this.currentStatus = '';
      this.$emit('areaChange', args);
    },
    handleSelect(e) {
      const index = e.target.className.indexOf('code-item self-slect');
      if (index < 0) {
        this.showSelect = false;
        this.currentStatus = '';
      }
      console.log(index);
    },
    inputBlurHandler() {
      this.value = filterBlank(this.value);
      if (!this.inputValue) {
        this.currentFocus = '';
      }
    },
    inputFocusHandler() {
      this.$emit('input-focus');
      this.currentFocus = 'input-focus';
    },
    selectFocusHandler() {
      this.currentStatus = 'is-active';
    },
    selectBlurHandler() {
      this.currentStatus = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.phone-email-input-container::after {
  content: attr(data-placeholder);
  display: block;
  position: absolute;
  top: 28px;
  left: 10px;
  padding: 0 4px;
  font-size: 16px;
  font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  color: #E6E6E6;
  line-height: 16px;
  pointer-events: none;
  transition: all .2s cubic-bezier(.4,0,.2,1), max-width .324s cubic-bezier(.4,0,.2,1);
  z-index: 1;
}

.input-focus::after {
  transform: translateY(-22px);
  font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.6);
  line-height: 14px;
  font-size: 12px;
  background-color: #fff;
}

.login-account-box {
  position: relative;

  .phone-country-code-container {
    width: 100%;
    height: 100%;
    color: var(--txt-color-lv2);
    font-size: 16px;
    line-height: 50px;

    .phone-country-code-text {
      margin-right: 8px;
    }

    .iconfont {
      color: var(--txt-color-lv2);
      font-size: 9px;
    }
  }

  .select-container {
    position: absolute;
    left: 0;
    right: 20px;
    z-index: 17;
    top: 102px;
    width: 100%;
    height: 260px;
    background: #fff;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow-y: scroll;

    .code-list {
      .code-item {
        position: relative;
        height: 36px;
        margin: 10px 20px 10px 10px;
        padding: 0 10px;
        border-radius: 2px;
        line-height: 36px;
        font-size: 14px;
        color: var(--txt-color-lv2);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.active {
          background: var(--txt-color-link);
          color: #fff;
        }
      }
    }
  }
}
</style>
